import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import moment from 'moment';
import { Tabs, Table, Modal, Button } from 'antd';
import {Panel} from './Panel';

const { confirm } = Modal;
const { TabPane } = Tabs;

const GET_CURRENT_USER_TIME_LOGS = gql`
	query getUserTimeLogs($startDate: Date, $endDate: Date) {
		currentUser {
			id
			role
			timeLogEntries(startDate: $startDate, endDate: $endDate) {
				id
				organization {
					id
					name
				}
				job{
					id
					title
				}
				status
				approved
				startTime
				endTime
			}
		}
	}
`;

const DELETE_TIME_LOG_ENTRY = gql`
	mutation deleteTimeLogEntry($id: ID!) {
		deleteTimeLogEntry(id: $id) {
			id
		}
	}
`;

export default function TimeSheet() {
	const { t } = useTranslation();
	const [deleteTimeLogEntry, { loading: deleteLoading }] = useMutation(DELETE_TIME_LOG_ENTRY, {
		refetchQueries: ['getUserTimeLogs'],
	});

	const { loading, error, data } = useQuery(GET_CURRENT_USER_TIME_LOGS, {
		fetchPolicy: 'cache-first',
	});

	const groupedEntries =
		!loading && !error && data
			? data.currentUser.timeLogEntries.reduce(
					(acc, e) => {
						const isMonthOpen = moment().isBefore(
							moment.unix(e.startTime).isBefore(moment.unix(e.startTime).date(5))
								? moment.unix(e.startTime).date(5)
								: moment
										.unix(e.startTime)
										.add(1, 'month')
										.date(5),
							'day'
						);
						const parsedEntry = {
							key: e.id,
							workItem: e.job.title,
							organization: e.organization.name,
							date: moment.unix(e.startTime).format('MMM[.] Do[,] YYYY'),
							startTime: moment.unix(e.startTime).format('HH:mm'),
							endTime: moment.unix(e.endTime).format('HH:mm'),
							totalTime: moment
								.utc(moment.duration(e.endTime - e.startTime, 'seconds').as('milliseconds'))
								.format('HH:mm'),
							isMonthOpen,
						};

						switch (e.status) {
							case 'APPROVED':
								acc.approved.push(parsedEntry);
								return acc;
							case 'DECLINED':
								acc.declined.push(parsedEntry);
								return acc;
							case 'PENDING':
								acc.pending.push(parsedEntry);
								return acc;
							default:
								return acc;
						}
					},
					{ pending: [], approved: [], declined: [] }
			  )
			: { pending: [], approved: [], declined: [] };

	const columns = [
		{
			title: t('work_item'),
			dataIndex: 'workItem',
			key: 'workItem',
		},
		{
			title: t('organization'),
			dataIndex: 'organization',
			key: 'organization',
		},
		{
			title: t('date'),
			dataIndex: 'date',
			key: 'date',
		},
		{
			title: t('start_time'),
			dataIndex: 'startTime',
			key: 'startTime',
		},
		{
			title: t('end_time'),
			dataIndex: 'endTime',
			key: 'endTime',
		},
		{
			title: t('total_time'),
			dataIndex: 'totalTime',
			key: 'totalTime',
		}
		
	];

	const columnsForPending = [...columns]
	
	columnsForPending.push({
		title: 'Action',
		key: 'action',
		render: (_, record) =>			
				<Button
					onClick={() =>
						confirm({
							title: 'Are you sure delete this time log entry?',
							content: 'This cannot be undone.',
							okText: 'Yes',
							okType: 'danger',
							cancelText: 'No',
							onOk() {
								return deleteTimeLogEntry({ variables: { id: record.key } });
							},
							onCancel() {
								console.log('Cancel');
							},
						})
					}
					loading={deleteLoading}
					type="destructive"
				>
					Delete
				</Button>
			
	})

	
	return (
		<Panel title={t('timesheet')}>
			<Tabs>
				<TabPane tab="Pending" key="pending">
					<Table className="responsiveTable" columns={columnsForPending} dataSource={groupedEntries.pending} />
				</TabPane>
				<TabPane tab="Approved" key="approved">
					<Table className="responsiveTable" columns={columns} dataSource={groupedEntries.approved} />
				</TabPane>
				<TabPane tab="Declined" key="declined">
					<Table className="responsiveTable" columns={columns} dataSource={groupedEntries.declined} />
				</TabPane>
			</Tabs>
		</Panel>
	);
}
