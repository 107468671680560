import React from 'react';
import { Link } from 'react-router-dom';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import Logo from '@bit/tibu.web.components.ui.logo';
import TbAvatar from 'components/TbAvatar';
import styles from './Header.module.scss';
import { Wrapper } from 'components/Wrapper';
import fragments from "fragments";

const GET_CURRENT_USER = gql`
	${fragments.User.BasicInfo}
	query getCurrentUser {
		currentUser {
			...BasicInfo
		}
	}
`;

function CurrentUser() {
	return (
		<Query query={GET_CURRENT_USER}>
			{({ loading, error, data }) => {
				if (loading) return <p>loading...</p>;
				if (error) return <p>Error :(</p>;

				const { firstName = '', lastName = '', avatarUrl } = data.currentUser;

				return <TbAvatar firstName={firstName} lastName={lastName} url={avatarUrl} />;
			}}
		</Query>
	);
}

export default function Header() {
	return (
		<div className={styles.header}>
			<Wrapper className={styles.content}>
				<Link to="/home">
					<Logo />
				</Link>
				<div style={{flex: 1}}></div>
				<CurrentUser />
			</Wrapper>
		</div>
	);
}